var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      ref: "form",
      staticClass: "itinerary step_1_hotel_form",
      attrs: {
        "data-uuid": _vm.itinerary.id,
        "data-uuid-raw": _vm.itinerary.attributes.uuid
      },
      on: { submit: _vm.submit }
    },
    [
      _c("input", {
        attrs: { type: "hidden", name: "uuid" },
        domProps: { value: _vm.itinerary.attributes.uuid }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "hotel_result", class: { overlay: _vm.locked } },
        [
          _vm.itinerary.attributes.pictures[0]
            ? _c("div", { staticClass: "image_wrap" }, [
                _c(
                  "a",
                  {
                    staticClass: "hotel_gallery thumb_a",
                    attrs: {
                      href: _vm.itinerary.attributes.pictures[0].url,
                      "data-fancybox": "gallery"
                    }
                  },
                  [
                    _c("div", { staticClass: "image" }, [
                      _c(
                        "div",
                        {
                          staticClass: "resize-and-crop",
                          staticStyle: {
                            width: "250px",
                            height: "200px",
                            "text-align": "center"
                          }
                        },
                        [
                          this.imagePath
                            ? _c("img", {
                                staticClass: "big_thumb",
                                staticStyle: { left: "-53px", top: "0px" },
                                attrs: {
                                  src: _vm._f("cloudinary")(this.imagePath, {
                                    h: 200,
                                    w: 250
                                  }),
                                  width: "250",
                                  height: "200"
                                }
                              })
                            : _c("img", {
                                staticClass: "big_thumb",
                                staticStyle: { left: "-53px", top: "0px" },
                                attrs: {
                                  src: _vm._f("cloudinary")(
                                    _vm.itinerary.attributes.pictures[0].url,
                                    { h: 200, w: 250 }
                                  ),
                                  width: "250",
                                  height: "200"
                                }
                              })
                        ]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "other_images" },
                  _vm._l(_vm.itinerary.attributes.pictures, function(pic) {
                    return _c(
                      "div",
                      { key: pic.url, staticClass: "small_image" },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "hotel_gallery",
                            attrs: { href: pic.url, "data-fancybox": "gallery" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "resize-and-crop",
                                staticStyle: { width: "60px", height: "40px" }
                              },
                              [
                                _c("img", {
                                  staticClass: "small_thumb_img",
                                  staticStyle: { left: "0px", top: "-25px" },
                                  attrs: {
                                    alt: pic.description,
                                    src: _vm._f("cloudinary")(pic.url, {
                                      h: 40,
                                      w: 60
                                    }),
                                    width: "60",
                                    height: "40"
                                  },
                                  on: {
                                    mouseenter: function($event) {
                                      return _vm.setImage(pic.url)
                                    },
                                    mouseleave: function($event) {
                                      return _vm.setImage(null)
                                    }
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  0
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "information" }, [
            _c("div", { staticClass: "name_group" }, [
              _c("div", { staticClass: "name" }, [
                _c(
                  "a",
                  {
                    attrs: {
                      fancybox: "",
                      "data-type": "ajax",
                      "data-src":
                        "/hotel_info/" + _vm.itinerary.attributes.hotel_id,
                      "data-options": "{touch:false,baseClass:null}",
                      href: "javascript:void(0);"
                    }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.itinerary.attributes.name) +
                        "\n          "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rating" },
                _vm._l(_vm.itinerary.attributes.rating, function(i) {
                  return _c("img", {
                    key: "star_" + _vm.itinerary.id + "_" + i,
                    attrs: {
                      src: _vm._f("cloudinary")("/assets/shared/star.png")
                    }
                  })
                }),
                0
              ),
              _vm._v(" "),
              _c("div", { staticClass: "address" }, [
                _c(
                  "a",
                  {
                    attrs: {
                      fancybox: "",
                      "data-type": "ajax",
                      "data-src":
                        "/hotel_map/" + _vm.itinerary.attributes.hotel_id,
                      "data-options": "{touch:false,baseClass:null}",
                      href: "javascript:void(0);"
                    }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.itinerary.attributes.address)
                    ),
                    _c("img", {
                      attrs: {
                        src: _vm._f("cloudinary")("/assets/shared/map_view.png")
                      }
                    })
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "price-box-hotel" }, [
              _c("div", { staticClass: "pax-price" }, [
                _c("div", { staticClass: "price" }, [
                  _vm._v(_vm._s(_vm.itinerary.attributes.price_pax))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "smallprice" }, [
                  _vm._v(_vm._s(_vm._f("locale")("per person")))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "total-price" }, [
                _c("div", { staticClass: "price" }, [
                  _vm._v(_vm._s(_vm.itinerary.attributes.price))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "smallprice" }, [
                  _vm._v(_vm._s(_vm._f("locale")("totalt")))
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "hotel_clear" }),
            _vm._v(" "),
            _c("div", { staticClass: "description" }, [
              _vm.itinerary.attributes.tripadvisor
                ? _c("div", { staticClass: "tripadvisor" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm._f("locale")("TripAdvisor-betyg")) +
                        "\n            "
                    ),
                    _c("div", { staticClass: "tripadvisor_image" }, [
                      _c("img", {
                        attrs: {
                          src: _vm._f("cloudinary")(
                            _vm.itinerary.attributes.tripadvisor
                              .rating_image_url
                          )
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "tripadvisor_reviews" }, [
                      _vm._v(_vm._s(_vm._f("locale")("Baserat på")) + " "),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.itinerary.attributes.tripadvisor.web_url,
                            target: "_blank"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("locale")(
                                "%{num} omdömen",
                                _vm.itinerary.attributes.tripadvisor.num_reviews
                              )
                            )
                          )
                        ]
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(
                "\n\n        " +
                  _vm._s(_vm.itinerary.attributes.short_description) +
                  "\n        "
              ),
              _c(
                "a",
                {
                  attrs: {
                    fancybox: "",
                    "data-type": "ajax",
                    "data-src":
                      "/hotel_info/" + _vm.itinerary.attributes.hotel_id,
                    "data-options": "{touch:false,baseClass:null}",
                    href: "javascript:void(0);"
                  }
                },
                [_vm._v("Läs mer")]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "rooms" },
              _vm._l(_vm.itinerary.attributes.rooms, function(rooms) {
                return _c(
                  "div",
                  {
                    key:
                      "hr_" +
                      _vm.itinerary.attributes.hotel_id +
                      "_" +
                      rooms.room_index,
                    staticClass: "room"
                  },
                  [
                    _c("div", { staticClass: "headline" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("locale")("Rum %{num}", rooms.room_index)
                        ) + " "
                      ),
                      _c("div", { staticClass: "pax-text" }, [
                        _vm._v(
                          "(" + _vm._s(_vm.roomText(rooms.room_index)) + ")"
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "options" },
                      _vm._l(rooms.room, function(room, index) {
                        return _c(
                          "label",
                          {
                            key:
                              "hr_" +
                              _vm.itinerary.attributes.hotel_id +
                              "_" +
                              rooms.room_index +
                              "_" +
                              (index + 1) +
                              "_" +
                              room.uuid,
                            staticClass: "table"
                          },
                          [
                            _c("ul", { staticClass: "table_div" }, [
                              _c("li", { staticClass: "radio" }, [
                                _c("input", {
                                  attrs: {
                                    type: "radio",
                                    name:
                                      "hr_" +
                                      _vm.itinerary.attributes.hotel_id +
                                      "_" +
                                      rooms.room_index,
                                    "data-price": room.price_raw,
                                    disabled: _vm.lockedRooms
                                  },
                                  domProps: { value: room.uuid },
                                  on: { change: _vm.onRoomChange }
                                })
                              ]),
                              _vm._v(" "),
                              _c("li", { staticClass: "li_1" }, [
                                _vm._v(_vm._s(room.description))
                              ]),
                              _vm._v(" "),
                              _c("li", { staticClass: "li_2" }, [
                                _vm._v(_vm._s(room.boardtype_text))
                              ]),
                              _vm._v(" "),
                              _c("li", { staticClass: "li_3" }, [
                                _vm._v(_vm._s(room.price_diff))
                              ])
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "continue-button-hotel" },
              [
                _vm.selected
                  ? [
                      _c(
                        "div",
                        {
                          staticClass: "spinner",
                          staticStyle: { "text-align": "center" }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm._f("cloudinary")(
                                "/assets/shared/ajax-loader_white.gif"
                              )
                            }
                          })
                        ]
                      )
                    ]
                  : [
                      _c("div", { staticClass: "y-button" }, [
                        _c("input", {
                          staticClass: "hotel",
                          attrs: { type: "submit", name: "commit" },
                          domProps: { value: _vm.submitText }
                        })
                      ])
                    ]
              ],
              2
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }